<template>
    <div>Playground</div>
</template>

<script>
export default {
  name: "DevPlayground",
};
</script>

