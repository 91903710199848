<template>
    <div>
        Favoriten
    </div>
</template>

<script>
export default {
  name: "FavoritesView",
};
</script>