<template>
  <div class="wimz-wrapper">
    <notification-handler :max-length-of-notifications="5"/>
    <header-component/>
    <router-view/>
    <footer-component/>
  </div>
</template>
<script>
import NotificationHandler from "@/components/organisms/NotificationHandler.vue";
import HeaderComponent from "./components/organisms/HeaderComponent.vue";
import FooterComponent from "./components/organisms/FooterComponent.vue";

export default {
  name: "StartView",
  components: {
    NotificationHandler,
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/main.scss";

.wimz-wrapper {
  position: relative;
  padding-top: $header-height;
  padding-bottom: $footer-height;
  height: 100vh;
  overflow-y: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
