<template>
    <div class="add-comment">
      <form class="add-comment__form" @submit.prevent="submitForm">
        <textarea class="add-comment__textarea" :class="{'add-comment__textarea--open': isOpen}" v-model="text" placeholder="Kommentar"/>
        <button class="add-comment__submit-button" :class="{'add-comment__submit-button--disabled' : isOpen && !text}" :disabled="isOpen && !text" @click.prevent="changeButton">
          <i v-if="isOpen" class="bi bi-send-fill"></i>
          <i v-else class="bi bi-chat-right-text-fill"></i>
        </button>
      </form>
    </div>
</template>

<script>
export default {
  name: "AddCommentForm",

  data() {
    return {
      text: null,
      isOpen: false,
    };
  },

  methods: {
    changeButton() {
      if (this.isOpen && this.text?.length) this.submitForm();
      this.isOpen = !this.isOpen;
    },
    submitForm() {
      this.$emit("add", this.text);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-comment {
  position: fixed;
  bottom: $footer-height;
  left: $grid-gutter-width / 2;
  right: $grid-gutter-width / 2;
  height: 0;

  &__form {
    height: 100%;
  }

  &__textarea {
    width: 100%;
    height: 100px;

    transition-property: transform;
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    transition-duration: 0.4s;
    transform: translateY(0px);
    resize: none;
    background-color: $base-color-blue;
    color: $base-color-white;
    border-radius: 15px;
    border: 2px solid $base-color-white;
    padding: 10px;

    &--open {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.43, 2.12, 0.66, 0.78);
      transition-duration: 0.4s;
      transform: translateY(-200px);
    }
  }

  &__submit-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
    bottom: 25px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    border: none;
    background-color: $base-color-blue;
    color: $base-color-white;
    font-size: 15px;
  }

  button.add-comment__submit-button--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}
</style>