<template>
    <button 
      class="button"
      :class="[
        {
          'button--primary': buttonType === 'primary'
        },
        {
          'button--secondary': buttonType === 'secondary'
        },
        {
          'button--success': buttonType === 'success'
        },
        {
          'button--bordered': bordered
        },
        {
          'button--disabled': disabled
        },
        `button--${size}`
      ]"
      v-bind="$attrs"
      :disabled="disabled"
    >
        <slot/>
    </button>
</template>

<script>
import VueTypes from "vue-types";
export default {
  name: "BasicButton",

  props: {
    buttonType: VueTypes.oneOf(["primary", "secondary", "success"]).def(
      "primary"
    ),
    size: VueTypes.oneOf(["s", "m", "l"]).def("m"),
    bordered: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $base-font-size;
  border: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &--bordered {
    border: 2px solid $base-color-white;
  }

  &--primary {
    background-color: $base-color-blue;
    color: $base-color-white;

    &:hover,
    &:focus {
      background-color: $base-color-white;
      color: $base-color-blue;
    }
  }

  &--secondary {
    background-color: $base-color-white;
    color: $base-color-blue;
    border: 1px solid $base-color-gray;

    &:hover,
    &:focus {
      background-color: $base-color-light-gray;
    }
  }

  &--success {
    background-color: $base-color-green;
    color: $base-color-white;

    &:hover,
    &:focus {
      background-color: rgba($base-color-green, 0.8);
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: $base-color-medium-gray;
  }

  &--s {
    height: 24px;
    border-radius: 8px;
    padding: 5px 10px;
  }

  &--m {
    min-height: 40px;
    border-radius: 10px;
    padding: 10px;
  }

  &--l {
    min-height: 50px;
    border-radius: 15px;
    padding: 15px;
  }
}
</style>
