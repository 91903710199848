<template>
  <div class="notification-box alert alert-primary alert-dismissible fade show d-flex align-items-center" role="alert">
    <div class="notification-box__content">
      <div v-if="$slots.header" class="notification-box__header">
        <slot name="header" />
      </div>
      <div v-if="$slots.body" class="notification-box__body">
        <slot name="body" />
      </div>
    </div>
    <div class="notification-box__button-wrapper">
      <button class="notification-box__button btn-close" type="button" xata-bs-dismiss="alert" aria-label="Close" @click="$emit('remove', id)">
      </button>
    </div>
  </div>
</template>

<script>
import VueTypes from "vue-types";

export default {
  name: "NotificationBox",
  props: {
    type: VueTypes.oneOf(["error", "info", "warning", "success"]).def("info"),
    id: VueTypes.string.def("").isRequired,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_vendors.scss";
</style>