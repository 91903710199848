export const search = {
    namespaced: true,
    state: {
        start: null,
        destination: null,
        date: null,
        time: null
    },
    getters: {
        getLastSearch: (state) => {
            return state
        }
    },
    mutations: {
        setSearch(state, payload) {
            state.start = payload?.start
            state.destination = payload?.destination
            state.date = payload?.date
            state.time = payload?.time
        }
    },
}

