<template>
  <div class="notification-handler">
    <div
      v-for="notification in getAllNotifications"
      :key="notification.id"
      class="notification-handler__noticiation-wrapper"
    >
      <NotificationBox
        :id="notification.id"
        :type="notification.type"
        @remove="handleRemove"
      >
        <template v-slot:header>{{ notification.headline }}</template>
        <template v-slot:body>{{ notification.text }}</template>
      </NotificationBox>
    </div>
  </div>
</template>

<script>
import NotificationBox from "@/components/molecules/NotificationBox";
import VueTypes from "vue-types";
export default {
  name: "NotificationHandler",

  components: {
    NotificationBox,
  },

  props: {
    maxLengthOfNotifications: VueTypes.number.def(4),
  },

  computed: {
    getAllNotifications() {
      //handler will show the first 4 by default
      return this.$store.getters.getAllNotifications(
        this.maxLengthOfNotifications
      );
    },
  },

  methods: {
    handleRemove(id) {
      this.$store.commit("removeNotification", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-handler {
  position: absolute;
  top: $header-height;
  width: 100%;
}
</style>
