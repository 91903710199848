<template>
    <div class="leg-card" v-if="!leg.walking && (leg.tripId || leg.id)">
        <div class="leg-card__badges">
            <span class="badge leg-card__badge leg-card__badge--vehicle">{{leg.line.name}}</span>
            <span class="badge leg-card__badge leg-card__badge--cancelled" v-if="leg.cancelled">CANCELLED</span>
        </div>
        <div class="leg-card__start-wrapper" :class="{
                'leg-card__start-wrapper--delay': leg.departureDelay
            }">
            <div class="leg-card__icon-wrapper"><i class="bi bi-geo-alt leg-card__start-icon"></i> </div>
            <div class="leg-card__arrival">
                <div class="leg-card__arrival-header">
                    <b>{{localTimeString(leg.plannedDeparture)}} <span class="leg-card__delay" v-if="leg.departureDelay">+{{secondsToMinutes(leg.departureDelay)}}</span></b>
                    <div class="leg-card__platform">
                        Gleis: {{leg.departurePlatform}}
                    </div> 
                </div>
                <div>{{leg.origin.name}} </div>
            </div>
        </div>
        <div class="leg-card__direction-wrapper" :class="[
                {
                    'leg-card__direction-wrapper--departure-delay': leg.departureDelay
                },
                {
                    'leg-card__direction-wrapper--arrival-delay': leg.arrivalDelay
                },
                {
                    'leg-card__direction-wrapper--delay': (leg.arrivalDelay && leg.departureDelay)
                }
            ]">
            <div class="leg-card__icon-wrapper"><i class="bi bi-arrow-down leg-card__direction-icon"></i> </div>
            <div>{{leg.direction}}</div>
        </div>
        <div class="leg-card__destination-wrapper" :class="{
                'leg-card__start-wrapper--delay': leg.arrivalDelay
            }">
            <div class="leg-card__icon-wrapper"><i class="bi bi-geo-alt-fill leg-card__destination-icon"></i> </div>
            <div class="leg-card__departure">
                <div class="leg-card__departure-header">
                    <b>{{localTimeString(leg.plannedArrival)}} <span class="leg-card__delay" v-if="leg.arrivalDelay">+{{secondsToMinutes(leg.arrivalDelay)}}</span></b>
                    <div class="leg-card__platform">
                        Gleis: {{leg.arrivalPlatform}}
                    </div>
                </div>
                <div>{{leg.destination.name}} </div>

            </div>
        </div>
        <slot/>
        <!--<div>{{leg.tripId}}</div>-->
    </div>            
</template>

<script>
import VueTypes from "vue-types";
export default {
  name: "LegCard",

  props: {
    selectionButtonText: VueTypes.string.def(""),
    leg: VueTypes.shape({
      line: VueTypes.shape({
        name: VueTypes.string,
      }),
      start: VueTypes.shape({
        name: VueTypes.string,
      }),
      direction: VueTypes.string,
      destination: VueTypes.shape({
        name: VueTypes.string,
      }),
      departurePlatform: VueTypes.string,
      arrivalPlatform: VueTypes.string,
      arrival: VueTypes.string,
      departure: VueTypes.string,
      departureDelay: VueTypes.string,
      arrivalDelay: VueTypes.string,
    }).loose,
  },

  methods: {
    localTimeString(dateTime) {
      return new Date(dateTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    secondsToMinutes(seconds) {
      return seconds / 60;
    },
  },
};
</script>

<style lang="scss" scoped>
.leg-card {
  &__badges {
    margin-bottom: 10px;
  }

  &__badge + &__badge {
    margin-left: 5px;
  }

  &__badge {
    color: $base-color-blue;
    &--vehicle {
      background: transparent;
      border: 2px solid $base-color-blue;
    }
    &--cancelled {
      color: $base-color-white;
      background-color: $base-color-red;
      border: 2px solid $base-color-red;
    }
  }

  &__start-wrapper,
  &__direction-wrapper,
  &__destination-wrapper {
    display: flex;
    align-items: center;
    border-left: 4px solid $base-color-blue;
    padding-left: 10px;
    min-height: 50px;
  }

  &__start-wrapper,
  &__destination-wrapper {
    &--delay {
      border-left-color: $base-color-red;
    }
  }

  &__direction-wrapper {
    min-height: 35px;
    opacity: 0.5;

    &--arrival-delay {
      border-image: linear-gradient(
          to bottom,
          $base-color-blue,
          $base-color-red
        )
        1;
    }

    &--departure-delay {
      border-image: linear-gradient(to top, $base-color-blue, $base-color-red) 1;
    }

    &--delay {
      border-image: none;
      border-left-color: $base-color-red;
    }
  }

  &__departure,
  &__arrival {
    width: 100%;
  }

  &__arrival-header,
  &__departure-header {
    display: flex;
    width: 100%;
  }

  &__platform {
    margin-left: auto;
  }

  &__start-icon,
  &__destination-icon {
    //margin-right: 10px;
    font-size: 20px;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }

  &__direction-icon {
    font-size: 15px;
  }

  &__delay {
    color: $base-color-red;
  }
}
</style>