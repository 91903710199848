import { supabase } from "@/js/supabase/supabase.js";
import axios from 'axios'
import { isDevelopmentEnvironment } from "@/js/helper/env.js";
import router from "@/router";
const token = supabase.auth.session()?.access_token;

export default async function api(config) {

    const defaultConfig = {
        method: 'get',
        baseURL: isDevelopmentEnvironment ? "http://localhost:3000/api" : "https://wimz-middleware.herokuapp.com/api",
        timeout: 3000,
        headers: { 'X-Supabase-Auth': token, 'Access-Control-Allow-Origin': '*' }
    }
    try {
        const response = await axios({
            method: "get",
            ...defaultConfig,
            ...config,
        });
        return response;
    } catch (error) {
        if (error.response?.status === 401) {
            await supabase.auth.signOut()
            router.push('signIn')
        }
        return error
    }
}