<template>
  <div class="search-panel">
    <leg-card class="search-panel__leg-card" :leg="leg"/>
  </div>
</template>

<script>
import VueTypes from "vue-types";
import LegCard from "@/components/molecules/LegCard.vue";
export default {
  components: {
    LegCard,
  },

  name: "SearchPanel",

  data() {
    return {
      minimized: false,
    };
  },

  props: {
    leg: VueTypes.any,
  },

  methods: {
    handleEditClick() {
      this.$emit("editClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-panel {
  margin-top: 15px;
  border-radius: 10px;
  background-color: $base-color-white;
  padding: 10px;
  color: $base-color-blue;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);
  margin-bottom: 30px;
}
</style>