import LoginView from '@/views/LoginView'
import LogoutView from '@/views/LogoutView'
import RegistrationView from '@/views/RegistrationView'
import PasswordResetView from '@/views/PasswordResetView'
import UpdatePasswordView from '@/views/UpdatePasswordView'
import SearchView from '@/views/SearchView'
import JourneysView from '@/views/JourneysView'
import CommentsView from '@/views/CommentsView'
import ProfileView from '@/views/ProfileView'
import FavoritesView from '@/views/FavoritesView'
import ErrorView from '@/views/ErrorView'

export default [
    {
        path: "/sign-out",
        name: "sign-out",
        component: LogoutView,
        meta: {
            titleName: "SignOut"
        },
    },
    {
        path: "/sign-in",
        name: "sign-in",
        component: LoginView,
        meta: {
            // redirectIfAuthenticated: "search",
            titleName: "SignIn"
        },
    },
    {
        path: "/sign-up",
        name: "sign-up",
        component: RegistrationView,
        meta: {
            redirectIfAuthenticated: "search",
            titleName: "SignUp"
        },
    },
    {
        path: "/update-password",
        name: "update-password",
        component: UpdatePasswordView,
        meta: {
            redirectIfAuthenticated: "search",
            titleName: "Password ändern"
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfileView,
        meta: {
            requiresAuth: true,
            titleName: "Profil"
        },
    },
    {
        path: "/password-reset",
        name: "password-reset",
        component: PasswordResetView,
        meta: {
            titleName: "Passwort vergessen"
        }
    },
    {
        path: "/search",
        name: "search",
        component: SearchView,
        meta: {
            requiresAuth: true,
            titleName: "Suche"
        },
    },
    {
        path: "/journeys",
        name: "journeys",
        component: JourneysView,
        meta: {
            requiresAuth: true,
            titleName: "Verbindungen"
        },
    },
    {
        path: "/comments",
        name: "comments",
        component: CommentsView,
        meta: {
            requiresAuth: true,
            titleName: "Kommentare"
        },
    },
    {
        path: "/favorites",
        name: "favorites",
        component: FavoritesView,
        meta: {
            requiresAuth: true,
            titleName: "Favoriten"
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Ups...",
        component: ErrorView,
        meta: {
            requiresAuth: true,
            titleName: "404"
        },
    },
    {
        path: "/",
        name: "Start",
        component: LoginView,
        meta: {
            titleName: "Start"
        },
    },
];