<template>
    <footer>
        <router-link class="footer__link" to="profile">
          <small-icon-with-label icon="person-circle" label="Profil"></small-icon-with-label>
        </router-link>
        <router-link class="footer__link" to="search">
          <small-icon-with-label icon="search" label="Suche"></small-icon-with-label>
        </router-link>
        <router-link class="footer__link" to="favorites">
          <small-icon-with-label icon="bookmark-heart-fill" label="Favoriten"></small-icon-with-label>
        </router-link>
    </footer>
</template>

<script>
import SmallIconWithLabel from "@/components/atoms/SmallIconWithLabel";
export default {
  name: "FooterComponent",

  components: {
    SmallIconWithLabel,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_functions.scss";
footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: $footer-height;
  background-color: $color-bg-footer;
  color: $color-footer;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);
  z-index: z("footer");
}

.footer {
  &__link {
    height: 30px;
    min-width: 30px;
    text-decoration: none;
  }
}
</style>