import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "@/../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "@/scss/main.scss";

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://f39497cd73934a3b86b9524772196088@o1354169.ingest.sentry.io/6637539",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "tst-wimz.degemann.de", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});


app.use(store).use(router).mount('#app')
