<template>
    <div class="container">
        <div class="row">
            <LoadingAnimation v-if="loading" class="comments-view__loading-animation"/>
            <div v-else class="col-12 comments-view__comments-wrapper">
                <search-panel :leg="leg" class="comments-view__search-panel"/>
                <div v-if="commentsOfTrip.length" class="comments-view__comments-list-wrapper">
                  <CommentsList :commentsList="commentsOfTrip" @downVote="handleDownVote" @upVote="handleUpVote"/>
                </div>
                <div v-else>
                  <small-icon-with-label class="comments-view__no-comments-icon" icon="robot" label="Füge den ersten Kommentar hinzu!"/>
                </div>
                <AddCommentForm @add="addComment"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import CommentsList from "@/components/organisms/CommentsList.vue";
import SearchPanel from "@/components/organisms/SearchPanel.vue";
import AddCommentForm from "@/components/organisms/AddCommentForm.vue";
import LoadingAnimation from "@/components/atoms/LoadingAnimation.vue";
import SmallIconWithLabel from "@/components/atoms/SmallIconWithLabel.vue";
import { supabase } from "@/js/supabase/supabase.js";
import api from "@/js/api";

export default {
  name: "CommentsView",
  components: {
    CommentsList,
    AddCommentForm,
    SearchPanel,
    LoadingAnimation,
    SmallIconWithLabel,
  },

  data() {
    return {
      commentsOfTrip: [],
      loading: true,
      leg: null,
    };
  },

  async mounted() {
    await this.getAllTripComments();
    const response = await axios.get(
      `https://v5.db.transport.rest/trips/${this.$route.query.trip}?lineName=${this.$route.query.lineName}`
    );

    this.leg = response.data;
    this.loading = false;
  },

  methods: {
    async getAllTripComments() {
      const linename = decodeURI(this.$route.query.lineName);
      const tripId = decodeURI(this.$route.query.trip);
      try {
        const res = await api({
          method: "get",
          url: `/comments?trip_id=${tripId}&linename=${linename}`,
        });
        this.commentsOfTrip = res.data;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },

    async addComment(text) {
      const linename = decodeURI(this.$route.query.lineName);
      const tripId = decodeURI(this.$route.query.trip);
      try {
        const res = await api({
          method: "post",
          url: "/add-comment",
          data: {
            trip_id: tripId,
            comment: text,
            linename: linename,
          },
        });
        this.commentsOfTrip = res.data;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },

    handleDownVote(comment) {
      this.vote("down", comment);
    },

    handleUpVote(comment) {
      this.vote("up", comment);
    },

    async vote(voteType, comment) {
      try {
        await api({
          method: "post",
          url: "/vote",
          data: {
            id: comment.id,
            trip_id: comment.trip_id,
            vote_direction: voteType === "down" ? -1 : 1,
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      await supabase
        .from("trip_comments")
        .update({
          vote: voteType === "down" ? comment.vote - 1 : comment.vote + 1,
        })
        .eq("id", comment.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.comments-view {
  height: 100%;

  &__loading-animation {
    margin-left: auto;
    margin-right: auto;
    margin-top: 45%;
  }

  &__comments-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: $header-height;
    height: 100vh;
  }

  &__search-panel {
    flex: 0 0 auto;
  }

  &__comments-list-wrapper {
    flex: 1 1 auto;
    padding-top: 15px;
    overflow-y: auto;
    padding-bottom: $footer-height;
  }

  &__no-comments-icon {
    font-size: 50px;
  }
}
</style>

