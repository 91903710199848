<template>
    <div class="small-icon-with-label">
        <i class="small-icon-with-label__icon" :class="`bi bi-${icon}`"></i>
        <div v-if="label" class="small-icon-with-label__label">{{label}}</div>
    </div>
</template>

<script>
import VueTypes from "vue-types";
export default {
  name: "SmallIconWithLabel",

  props: {
    label: VueTypes.string.def(""),
    icon: VueTypes.string.def(""),
  },
};
</script>

<style lang="scss" scoped>
.small-icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__label {
    @include typo-s;
  }

  &__icon {
    @include typo-m;
  }
}
</style>