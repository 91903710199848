<template>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div 
            class="search-form search-form__small"  
            :class="{'search-form__small--minimized': minimized}"
            v-if="smallSearchBar"
          >
            <div v-if="!minimized">
              <div class="search-form__small-start-destination">
                <button class="search-form__small-edit-button" @click="smallSearchBar = false; minimized = false">
                  <i class="bi bi-pen-fill"></i>
                </button>
                <div class="search-form__small-start">
                  <i class="bi bi-geo-alt"></i> {{start}}
                </div>
                <i class="bi bi-arrow-down"></i>
                <div class="search-form__small-destination">
                  <i class="bi bi-geo-alt-fill"></i> {{destination}}
                </div>
              </div>
              <div class="search-form__small-date-time">
                <div class="search-form__small-date">
                  {{formattedDate}}
                </div>
                <div class="search-form__small-time">
                  {{time}}
                </div>
              </div>
            </div>
            <button class="search-form__minimize-button" @click="minimized = !minimized">
              <i v-if="minimized" class="bi bi-chevron-left"></i>
              <i v-else class="bi bi-chevron-right"></i>
            </button>
          </div>
          <form 
            v-else
            class="search-form" 
            @submit.prevent="submitForm"
          >
            <div class="row">
              <div class="col-12">
                <basic-input label="Start" class="search-form__input" :list="dataListStart" type="text" name="start" :value="start" @change="selectStart" @input="handleStartInput"/>
                <datalist ref="dataList" id="start-suggestions">
                    <option 
                      v-for="suggestion in startSuggestions" 
                      :key="suggestion.id"
                      :value="suggestion.name"/>
                </datalist>
              </div>
              <div class="col-12">
                <basic-input label="Ziel" class="search-form__input" :list="dataListDestination" type="text" name="destination" :value="destination" @change="selectDestination"  @input="handleDestinationInput"/>
                <datalist v-if="destinationSuggestionListIsVisible" id="destination-suggestions">
                    <option 
                      v-for="suggestion in destinationSuggestions" 
                      :key="suggestion.id" 
                      :value="suggestion.name"/>
                </datalist>
              </div>
              <div class="col-6">
                <basic-input label="Datum" class="search-form__input" type="date" name="date" :value="date" @change="date = $event.target.value" />
              </div>
              <div class="col-6">
                <basic-input label="Uhrzeit" class="search-form__input" type="time" name="time" :value="time" @change="time = $event.target.value"/>
              </div>
              <div class="col-6 col-md-3 search-form__options">
                <basic-toggle labelChecked="Ankunft" labelUnchecked="Abfahrt" @click="handleToggleClick"/>
              </div>
              <div class="col-6 col-md-3 search-form__options">
                <basic-button @click="setDateAndTime" class="search-form__date-time" size="s">Jetzt</basic-button>
              </div>
              <div class="col-12 col-md-6 ">
                <basic-button class="search-form__button" :disabled="!formIsSubmitable" size="m">Suchen</basic-button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row journey-list" ref="journeyList">
        <div class="col-12">
          <div v-if="loading" class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <JourneyList :journeyData="journeyData" v-else/>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import JourneyList from "@/components/organisms/JourneyList.vue";
import BasicInput from "@/components/atoms/BasicInput.vue";
import BasicButton from "@/components/atoms/BasicButton.vue";
let lastStartSuggestions = [];
let lastDestinationSuggestions = [];
import { mapGetters, mapMutations } from "vuex";
import BasicToggle from "@/components/atoms/BasicToggle.vue";
import { format } from "date-fns";

export default {
  name: "SearchView",

  components: {
    JourneyList,
    BasicInput,
    BasicToggle,
    BasicButton,
  },

  data() {
    return {
      finalStart: "",
      finalDestination: "",
      start: "",
      destination: "",
      date: null,
      time: null,
      startSuggestions: [],
      destinationSuggestions: [],
      startSuggestionListIsVisible: false,
      destinationSuggestionListIsVisible: false,
      journeyData: {},
      isDeparture: false,
      smallSearchBar: false,
      minimized: false,
      loading: false,
    };
  },

  mounted() {
    let lastSearch = this.getLastSearch;
    if (lastSearch.start) {
      this.start = lastSearch.start.name;
      this.finalStart = lastSearch.start;
      this.destination = lastSearch.destination.name;
      this.finalDestination = lastSearch.destination;
      this.date = lastSearch.date;
      this.time = lastSearch.time;
    }

    this.handleScroll();
  },

  watch: {
    start(newValue) {
      this.getStartSuggestions(newValue);
    },

    destination(newValue) {
      this.getDestinationSuggestions(newValue);
    },
  },

  computed: {
    ...mapGetters("search", ["getLastSearch"]),

    formattedDate() {
      return format(new Date(this.date), "dd.MM.yyyy");
    },

    dataListStart() {
      return this.startSuggestionListIsVisible ? "start-suggestions" : "";
    },

    dataListDestination() {
      return this.destinationSuggestionListIsVisible
        ? "destination-suggestions"
        : "";
    },

    formIsSubmitable() {
      return this.finalStart && this.finalDestination && this.date && this.time;
    },
  },

  methods: {
    ...mapMutations("search", ["setSearch"]),

    handleScroll() {
      this.$refs.journeyList.addEventListener("scroll", () => {
        this.minimized = true;
      });
    },

    async getStartSuggestions(query) {
      lastStartSuggestions = this.startSuggestions;
      this.startSuggestionListIsVisible = true;
      this.startSuggestions = [];
      const response = await axios.get(
        `https://v5.db.transport.rest/locations?query=${query}&results=5`
      );
      response.data.forEach((item) => {
        this.startSuggestions.push({
          id: item.id,
          name: item.name,
        });
      });
    },

    async getDestinationSuggestions(query) {
      lastDestinationSuggestions = this.destinationSuggestions;
      this.destinationSuggestionListIsVisible = true;
      this.destinationSuggestions = [];
      const response = await axios.get(
        `https://v5.db.transport.rest/locations?query=${query}&results=5`
      );
      response.data.forEach((item) => {
        this.destinationSuggestions.push({
          id: item.id,
          name: item.name,
        });
      });
    },

    selectStart(e) {
      this.start = e.target.value;
      this.startSuggestionListIsVisible = false;
      let tempStart = lastStartSuggestions.filter((item) => {
        return item.name === this.start;
      });
      this.finalStart = {
        id: tempStart[0]?.id,
        name: tempStart[0]?.name,
      };
      this.startSuggestions = [];
    },

    handleStartInput(e) {
      this.start = e.target.value;
    },

    handleDestinationInput(e) {
      this.destination = e.target.value;
    },

    selectDestination(e) {
      this.destination = e.target.value;
      this.destinationSuggestionListIsVisible = false;
      let tempDestination = lastDestinationSuggestions.filter((item) => {
        return item.name === this.destination;
      });
      this.finalDestination = {
        id: tempDestination[0]?.id,
        name: tempDestination[0]?.name,
      };
      this.destinationSuggestions = [];
    },

    handleToggleClick(value) {
      this.isDeparture = value;
    },

    async submitForm(e) {
      this.loading = true;
      e.preventDefault();

      this.setSearch({
        start: this.finalStart,
        destination: this.finalDestination,
        date: this.date,
        time: this.time,
      });

      let arrivalOrDeparture = this.isDeparture ? "depature" : "arrival";

      const response = await axios.get(
        `https://v5.db.transport.rest/journeys?from=${this.finalStart.id}&to=${this.finalDestination.id}&${arrivalOrDeparture}=${this.date}T${this.time}`
      );

      this.journeyData = response.data;
      this.smallSearchBar = true;
      this.loading = false;
    },

    setDateAndTime() {
      const now = new Date();
      this.date = now.toISOString().split("T")[0];
      this.time = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_vendors.scss";
@import "~@/scss/_functions.scss";

.search-form {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  background-color: $base-color-white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);
  padding: 10px;

  &__small {
    position: relative;
    width: 100%;
    margin-right: 0;
    margin-left: auto;
    color: $base-color-blue;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;

    &--minimized {
      width: 40px;
      height: 40px;
      left: auto;
    }

    &-edit-button {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
      border: none;
      background-color: transparent;
      color: $base-color-white;
    }

    &-date-time {
      width: 100%;
      display: flex;
      margin-right: 30px;
    }

    &-date,
    &-time {
      padding: 0 10px 10px 10px;
      background-color: $base-color-blue;
      color: $base-color-white;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &-time {
      margin-left: 10px;
    }

    &-start-destination {
      position: relative;
      padding: 10px;
      background-color: $base-color-blue;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      color: $base-color-white;
      margin-right: 30px;
    }
  }

  &__minimize-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    color: $base-color-blue;
  }

  &__input {
    width: 100%;
    margin-bottom: 10px;
  }

  &__button {
    width: 100%;
  }

  &__date-time {
    margin-top: 3px;
  }

  &__options {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.form-check {
  width: 50px;
}

.journey-list {
  padding-top: 15px;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: $footer-height + 85px;
}
</style>