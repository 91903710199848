<template>
    <header>
        <div class="header__first-box">
          <router-link to="search">
            <img class="header__logo" src="@/assets/logo.svg" alt="logo"/>
          </router-link>
            <!-- <button class="header__icon header__back-button header__button" @click="$router.go(-1)">
                <i class="bi bi-caret-left"></i> 
            </button> -->
        </div>
        <div class="header__title">
            {{currentRouteTitleName}}
        </div>
        <div class="header__second-box">
            <button class="header__button" @click="menuIsVisible = !menuIsVisible">
                <i v-if="menuIsVisible" class="bi bi-menu-up"></i>
                <i v-else class="bi bi-menu-down"></i>
            </button>
        </div>
        <sidebar-menu class="header__sidebar-menu" :class="{'header__sidebar-menu--visible': menuIsVisible}"/>
    </header>
</template>

<script>
import SidebarMenu from "@/components/organisms/SidebarMenu";
export default {
  name: "HeaderComponent",

  components: {
    SidebarMenu,
  },

  data() {
    return {
      menuIsVisible: false,
    };
  },

  computed: {
    currentRouteTitleName() {
      return this.$router.currentRoute.value.meta.titleName || "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_functions.scss";

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  background-color: $base-color-white;
  color: $base-color-blue;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);
  z-index: z("header");
}

.header {
  &__first-box,
  &__second-box {
    width: 100%;
  }

  &__title {
    font-size: $title-font-size;
    text-transform: uppercase;
    min-width: 50%;
    text-align: center;
  }

  &__second-box {
    display: flex;
    flex-direction: row-reverse;
  }

  &__button {
    font-size: 20px;
    border: none;
    background: transparent;
    color: $base-color-blue;
    border-radius: 10px;
    height: 30px;
    width: 30px;

    &:hover,
    &:focus {
      background-color: rgba($base-color-blue, 0.2);
    }
  }

  &__sidebar-menu {
    position: absolute;
    transform: translateY(-100%);
    right: $grid-gutter-width;
    transition: transform 0.2s ease-in-out;

    &--visible {
      transform: translateY(140px);
    }
  }

  &__logo {
    width: 50px;
  }
}
</style>