<template>
    <div class="basic-toggle" @click="handleClick">
        <div 
          class="basic-toggle__button" :class="[
            {'basic-toggle__button--checked' : isChecked}
          ]"
        >
          {{ labelChecked }}
        </div>
        <div 
          class="basic-toggle__button" :class="[
            {'basic-toggle__button--checked' : !isChecked}
          ]"
        >
          {{ labelUnchecked }}
        </div>
    </div>
</template>

<script>
import VueTypes from "vue-types";
export default {
  name: "BasicToggle",

  props: {
    labelChecked: VueTypes.string.def(""),
    labelUnchecked: VueTypes.string.def(""),
  },

  data() {
    return {
      isChecked: false,
    };
  },

  methods: {
    handleClick() {
      this.isChecked = !this.isChecked;
      this.$emit("click", this.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-toggle {
  display: flex;
  background-color: $base-color-light-gray;
  border-radius: 8px;
  padding: 3px;
  height: 30px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $base-color-gray;
    border-radius: 8px;
    padding: 3px;
    opacity: 0.5;
    cursor: pointer;

    &--checked {
      background-color: $base-color-blue;
      color: $base-color-white;
      opacity: 1;
    }

    &:hover,
    &:focus {
      border: 1px solid $base-color-white;
    }
  }

  &__button + &__button {
    margin-left: 5px;
  }
}
</style>