<template>
  <div>
      <CommentBox 
        class="comment-box"
        v-for="comment in commentsList" 
        :key="comment.id" 
        :vote="comment.vote"
        :voted="comment.voted"
        :last-update="lastUpdateOfComment(comment.created_at)"
        :user-name="comment.user_name"
        @downVote="$emit('downVote', comment)"
        @upVote="$emit('upVote', comment)"
      >
        {{ comment.comment }}
      </CommentBox>
    </div>
</template>

<script>
import CommentBox from "@/components/molecules/CommentBox.vue";
import VueTypes from "vue-types";
import { formatDistanceToNowStrict } from "date-fns";

export default {
  name: "CommentsList",

  components: {
    CommentBox,
  },

  props: {
    commentsList: VueTypes.arrayOf([VueTypes.object]).def(() => []),
  },

  methods: {
    lastUpdateOfComment(createdAt) {
      const result = formatDistanceToNowStrict(new Date(createdAt));
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-box + .comment-box {
  margin-top: 15px;
}
</style>