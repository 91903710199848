<template>
    <div class="container">
        <div class="row update-password">
            <div class="col-12 update-password__input-wrapper">
                <basic-input 
                    type="password"
                    label="Passwort" 
                    :value="password" 
                    @input="password = $event.target.value"
                    class="update-password__input"
                />
                <basic-input 
                    type="password"
                    label="Passwort bestätigen" 
                    :value="password_2" 
                    @input="password_2 = $event.target.value"
                    class="update-password__input"
                />
            </div>
            <div class="col-12 update-password__button-wrapper">
                <basic-button @click="updatePassword" :disabled="!password || !password_2 || (password != password_2) || loading">Passwort ändern</basic-button>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/js/api";
import BasicInput from "@/components/atoms/BasicInput.vue";
import BasicButton from "@/components/atoms/BasicButton.vue";
export default {
  components: { BasicInput, BasicButton },

  name: "UpdatePasswordView",
  data() {
    return {
      password: "",
      password_2: "",
      loading: false,
    };
  },
  methods: {
    async updatePassword() {
      this.loading = true;
      try {
        if (this.password === this.password_2) {
          await api({
            method: "post",
            url: "/update-password",
            data: {
              password: this.password,
            },
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.update-password {
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;

  &__input-wrapper {
    margin-bottom: 15px;
  }

  &__input + &__input {
    margin-top: 15px;
  }
}
</style>