<template>
  <div class="registration-view">
      <div class="container">
          <form class="row" @submit.prevent="handleSubmit">
            <basic-input label="Username" class="col-12 mb-2" :value="username" @change="username = $event.target.value" type="text" name="username" :error="false"/>
            <basic-input label="E-Mail-Adresse" class="col-12 mb-2" :value="email" @change="email = $event.target.value" type="email" name="username" :error="false"/>
            <basic-input label="Passwort" class="col-12 mb-2" :value="password" @change="password = $event.target.value" type="password" name="password" :error="false"/>
            <basic-input label="Passwort bestätigen" class="col-12 mb-2" :value="passwordRepeat" @change="passwordRepeat = $event.target.value" type="password" name="password-repeat" :error="false"/>
            <div class="registration-view__button-wrapper col-12">
              <basic-button :disabled="loading">
                Registrieren
              </basic-button>
            </div>
            <div v-if="loading" class="spinner-grow" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </form>
      </div>
  </div>
</template>

<script>
import { supabase } from "@/js/supabase/supabase.js";
import BasicInput from "../components/atoms/BasicInput.vue";
import BasicButton from "../components/atoms/BasicButton.vue";
export default {
  name: "RegistrationView",

  components: {
    BasicInput,
    BasicButton,
  },

  data() {
    return {
      email: "",
      password: "",
      passwordRepeat: "",
      username: "",
      error: "",
      loading: false,
    };
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      const { user, error } = await supabase.auth.signUp(
        {
          email: this.email,
          password: this.password,
        },
        {
          data: {
            username: this.username,
          },
        }
      );
      if (error) {
        this.error = error;
        this.$store.commit("setNotification", {
          headline: "FEHLER",
          text: error,
        });
        this.loading = false;
        return;
      }
      if (user) {
        this.$router.push("search");
      } else {
        this.error = "Unerwarteter Fehler ist aufgetreten";
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_vendors.scss";

.registration-view {
  padding-top: $header-height + 50px;
}
</style>
