import { createStore } from 'vuex'
import { search } from './modules/search'
import createPersistedState from "vuex-persistedstate";

const uuidv4 = () => ""

const persistedSearch = createPersistedState()

export default createStore({
  state: {
    notifications: []
  },
  getters: {
    getAllNotifications: (state) => (maxNotifications = 5) => {
      if (state.notifications.length <= maxNotifications) {
        return state.notifications;
      } else {
        return state.notifications.splice(0, maxNotifications);
      }
    },
  },
  mutations: {
    setNotification(state, payload) {
      if (payload.id && (payload.text || payload.headline)) {
        state.notifications.push(payload);
      } else {
        if (payload.id) {
          // it's not a valid notification because text is missing
        } else {
          //only id is missing => create id
          let newNotification = {
            ...payload,
            id: uuidv4(),
          };

          state.notifications.push(newNotification);
        }
      }
    },
    removeNotification(state, payload) {
      const id = payload;
      state.notifications = state.notifications.filter(
        (elem) => elem.id !== id
      );
    },
  },
  actions: {
  },
  modules: {
    search
  },
  plugins: [persistedSearch],
})
