<template>
    <div class="container">
        <div class="row password-reset">
            <div class="col-12 password-reset__input-wrapper">
                <basic-input 
                    label="E-Mail-Adresse" 
                    :value="email" 
                    @input="email = $event.target.value"
                />
            </div>
            <div class="col-12 password-reset__button-wrapper">
                <basic-button @click="resetPassword" :disabled="!email">Passwort zurücksetzen</basic-button>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/js/api";
import BasicInput from "@/components/atoms/BasicInput.vue";
import BasicButton from "@/components/atoms/BasicButton.vue";
export default {
  components: { BasicInput, BasicButton },

  name: "PasswordResetView",
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    async resetPassword() {
      this.loading = true;
      try {
        await api({
          method: "post",
          url: "/reset-password",
          data: {
            email: this.email,
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.password-reset {
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;

  &__input-wrapper {
    margin-bottom: 15px;
  }
}
</style>