<template>
    <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script>
export default {
  name: "LoadingAnimation",
};
</script>