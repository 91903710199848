<template>
    <div class="sidebar-menu">
      <ul class="sidebar-menu__list">
        <li v-for="(item, index) in list" class="sidebar-menu__item" :key="index">
          <router-link :to="item.route" class="sidebar-menu__link">
            <small-icon-with-label :icon="item.icon" :label="item.label"/>
          </router-link>
        </li>
      </ul>
    </div>
</template>

<script>
import SmallIconWithLabel from "@/components/atoms/SmallIconWithLabel";
export default {
  name: "SidebarMenu",

  components: {
    SmallIconWithLabel,
  },

  data() {
    return {
      list: [
        {
          label: "Profil",
          route: "profile",
          icon: "person-circle",
        },
        {
          label: "FAQ",
          route: "faq",
          icon: "patch-question",
        },
        {
          label: "Impressum",
          route: "imprint",
          icon: "info-circle",
        },
        {
          label: "Logout",
          route: "sign-out",
          icon: "emoji-frown",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_functions.scss";
.sidebar-menu {
  padding: 10px;
  border-radius: 10px;
  z-index: z("sidebar-menu");
  width: 85px;
  background-color: $base-color-white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item + &__item {
    margin-top: 10px;
  }

  &__link {
    text-decoration: none;
  }
}
</style>