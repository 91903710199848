<template>
    <div>
        Profil
    </div>
</template>

<script>
export default {
  name: "ProfileView",
};
</script>