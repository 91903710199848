<template>
    <div class="arrow-toggle">
        <div v-if="direction === 'upDown'">
            <i v-if="state" class="bi bi-arrow-up-circle" :class="{'arrow-toggle--light' : type === 'light'}"></i>
            <i v-else class="bi bi-arrow-down-circle" :class="{'arrow-toggle--light' : type === 'light'}"></i>
        </div>
        <div v-else-if="direction === 'leftRight'">
            <i v-if="state" class="bi bi-arrow-left-circle" :class="{'arrow-toggle--light' : type === 'light'}"></i>
            <i v-else class="bi bi-arrow-right-circle" :class="{'arrow-toggle--light' : type === 'light'}"></i>
        </div>
    </div>
</template>

<script>
import VueTypes from "vue-types";

export default {
  name: "ArrowToggle",

  props: {
    direction: VueTypes.oneOf(["upDown", "leftRight"]).def("upDown"),
    state: VueTypes.bool.def(false),
    type: VueTypes.oneOf(["light", "dark"]),
  },
};
</script>

<style lang="scss" scoped>
.arrow-toggle {
  cursor: pointer;

  &--light {
    color: $base-color-white;
  }
}
</style>