<template>
    <div class="basic-input">
        <label 
          v-if="label" 
          class="basic-input__label"
          :class="{'basic-input__label--error' : error}"
        >
          {{label}}
        </label>
        <input
          @input="$emit('input', $event)" 
          @change="$emit('change', $event)"
          v-bind="$attrs" 
          class="basic-input__input"
        />
    </div>
</template>

<script>
import VueTypes from "vue-types";
export default {
  ineritAttrs: false,

  name: "BasicInput",

  props: {
    label: VueTypes.string.def(""),
    error: VueTypes.bool.def(false),
  },
};
</script>

<style lang="scss" scoped>
.basic-input {
  &__input {
    height: 40px;
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid $color-border-input;
    padding: 0 10px;
  }

  &__label {
    color: $base-color-blue;
    padding-left: 7px;
    &--error {
      @include typo-bold;
      color: $base-color-red;
    }
  }
}
</style>