import { createRouter, createWebHistory } from 'vue-router'
import { supabase } from "@/js/supabase/supabase.js";
import routes from './routes.js'
import { isDevelopmentEnvironment } from "@/js/helper/env.js";
import DevPlayground from '@/devViews/DevPlayground'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

if (isDevelopmentEnvironment) {
  router.addRoute({
    path: "/dev",
    name: "playground",
    component: DevPlayground,
  });
}

router.beforeEach((to, from, next) => {
  const currentUser = supabase.auth.user();
  const redirectIfAuthenticated = to?.meta?.redirectIfAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("sign-out");
  } else if (!requiresAuth && currentUser && redirectIfAuthenticated)
    next(redirectIfAuthenticated);
  else next();
});

export default router
