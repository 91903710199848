<template>
    <div>
        <JourneyCard 
            v-for="journey in journeyList" 
            :key="journey" 
            :start="journey.legs[0].origin.name"
            :destination="journey.legs[journey.legs.length - 1].destination.name"
            :legs="journey.legs"
            :arrival-time="journey.legs[journey.legs.length - 1].plannedArrival"
            :departure-time="journey.legs[0].plannedDeparture"
        />
    </div>
</template>

<script>
import VueTypes from "vue-types";
import JourneyCard from "@/components/molecules/JourneyCard.vue";
export default {
  name: "JourneyList",

  components: {
    JourneyCard,
  },

  props: {
    journeyData: VueTypes.shape({}).loose.def(() => {}),
  },

  computed: {
    journeyList() {
      return this.journeyData?.journeys || [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>