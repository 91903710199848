<template>
    <div class="comment-box" v-if="$slots.default()[0].children">
        <div class="comment-box__vote-wrapper" :class="{'comment-box__vote-wrapper--disabled': isVoted || voted }">
            <button @click="upVoteComment" class="comment-box__vote-button">
              <i class="comment-box__vote-icon" :class="[
              {'bi bi-caret-up-fill': isVoted === 'up'},
              {'bi bi-caret-up': isVoted !== 'up'},
              ]"></i>
            </button>
            <span>{{tempVote}}</span>
            <button @click="downVoteComment"><i class="comment-box__vote-icon bi bi-caret-down"></i></button>
        </div>
        <div class="comment-box__content-wrapper">
            <div class="comment-box__header">
                <div class="comment-box__username">
                    <b>{{userName}}</b>
                </div>
                <div class="comment-box__time">
                    {{lastUpdate}}
                </div>
            </div>
            <div class="comment-box__content">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from "vue-types";
export default {
  name: "CommentBox",

  props: {
    vote: VueTypes.number.isRequired,
    voted: VueTypes.bool.def(false),
    lastUpdate: VueTypes.string.def("N/A"),
    userName: VueTypes.string.def(""),
  },

  data() {
    return {
      tempVote: 0,
      isVoted: null,
    };
  },

  mounted() {
    this.tempVote = this.vote;
  },

  methods: {
    downVoteComment() {
      this.isVoted = "down";
      this.tempVote = this.tempVote - 1;
      this.$emit("downVote");
    },

    upVoteComment() {
      this.isVoted = "up";
      this.tempVote = this.tempVote + 1;
      this.$emit("upVote");
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-box {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $base-color-blue;
  padding: 10px;
  color: $base-color-white;

  &__header {
    display: flex;
    opacity: 0.5;
    margin-bottom: 10px;
  }

  &__time {
    margin-left: auto;
    padding-left: 10px;
    min-width: 42px;
  }

  &__vote-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40px;

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__vote-icon {
    color: $base-color-white;
  }

  &__vote-wrapper button {
    border: none;
    background: transparent;
    width: 30px;
    height: 30px;
    font-size: 14px;
    border-radius: 5px;

    &:hover,
    &:focus {
      background-color: rgba($base-color-light-gray, 0.5);
    }
  }

  &__content-wrapper {
    width: 100%;
  }
}
</style>
