<template>
    <div class="status-bar">
        <div v-for="leg in listOfLegs" 
            :key="leg.tripId"
            class="status-bar__item"
            :class="[
                {'status-bar__item--warning' : legHasWarnings(leg)},
                {'status-bar__item--error' : legHasErrors(leg)},
            ]"
        >
        </div>
    </div>
</template>

<script>
import VueTypes from "vue-types";
export default {
  name: "StatusBar",

  props: {
    listOfLegs: VueTypes.array.def(() => []),
  },

  methods: {
    legHasErrors(leg) {
      if (leg.cancelled) return true;
      return false;
    },

    legHasWarnings(leg) {
      if (leg.departureDelay || leg.arrivalDeplay) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.status-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 5px;

  &__item + &__item {
    margin-top: 4px;
  }

  &__item {
    width: 100%;
    height: 100%;
    background-color: $base-color-green;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px $base-color-green;

    &--warning {
      background-color: $base-color-orange;
      box-shadow: 0px 0px 10px 0px $base-color-orange;
    }

    &--error {
      background-color: $base-color-red;
      box-shadow: 0px 0px 10px 0px $base-color-red;
    }
  }
}
</style>