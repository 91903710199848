<template>
    <div class="journey-card">
      <status-bar class="journey-card__status-bar" :list-of-legs="legs"/>
        <div class="journey-card__content">
            <div class="journey-card__start"  :class="{'journey-card__start--open' : legsVisible}">
                <div class="journey-card__start-data">
                  <i class="bi bi-geo-alt"></i> {{start}}
                  <div>{{formattedTime(departureTime)}}</div>
                </div>
                <div class="journey-card__legs-toggle" @click="changeLegsVisibility">
                  <basic-button 
                    class="journey-card__legs-button"
                    button-type="primary"
                    bordered
                  >
                    Fahrten 
                    <arrow-toggle 
                      class="journey-card__arrow-toggle" 
                      :state="legsVisible" 
                      direction="upDown" 
                      type="light"
                    />
                
                </basic-button>
              </div>
            </div>
            <div 
              class="journey-card__change-over-overview" 
              :class="{'journey-card__change-over-overview--open' : legsVisible}"
            >
              <img src="@/assets/connection.svg" v-if="!legsVisible">
            </div>
            <div class="journey-card__legs" v-if="legsVisible">
              <div               
                v-for="(leg, index) in legs" 
                :key="leg.tripId">
                <LegCard 
                  class="journey-card__leg" 
                  :leg="leg"
                >
                    <basic-button 
                      @click="handleCommentClick(leg.tripId, leg.line.name)" 
                      class="journey-card__leg-card-button"
                      size="m"
                    >
                      Kommentare
                      <i class="bi bi-chat-left-text journey-card__leg-card-comment-icon"></i>
                    </basic-button>   
                </LegCard>
                <div class="journey-card__change-over">
                  <img v-if="!leg.walking && leg.tripId && (legs.length - 1) != index" src="@/assets/connection-white.svg">
                </div>
              </div>
            </div>
            <div class="journey-card__destination" :class="{'journey-card__destination--open' : legsVisible}">
                <div>
                  <i class="bi bi-geo-alt-fill"></i> {{destination}}
                  <div>{{formattedTime(arrivalTime)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from "vue-types";
import ArrowToggle from "@/components/atoms/ArrowToggle.vue";
import BasicButton from "@/components/atoms/BasicButton.vue";
import LegCard from "@/components/molecules/LegCard.vue";
import StatusBar from "@/components/molecules/StatusBar.vue";
import { format } from "date-fns";

export default {
  name: "JourneyCard",

  components: {
    ArrowToggle,
    LegCard,
    BasicButton,
    StatusBar,
  },

  props: {
    start: VueTypes.string.def(""),
    departureTime: VueTypes.string.def(""),
    destination: VueTypes.string.def(""),
    arrivalTime: VueTypes.string.def(""),
    legs: VueTypes.array.def(() => []),
  },

  data() {
    return {
      legsVisible: false,
    };
  },

  methods: {
    formattedTime(time) {
      return format(new Date(time), "h:m");
    },

    changeLegsVisibility() {
      this.legsVisible = !this.legsVisible;
    },

    handleCommentClick(tripId, lineName) {
      let encodedTripId = encodeURI(tripId);
      this.$router.push({
        name: "comments",
        query: { trip: encodedTripId, lineName: lineName },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.journey-card {
  position: relative;
  border-radius: 15px;
  padding: 10px;
  padding-left: 23px;
  background-color: $base-color-white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);

  &__status-bar {
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
  }

  &__start,
  &__destination {
    display: flex;
    align-items: center;
    background-color: $base-color-blue;
    border-radius: 10px;
    width: 100%;
    padding: 0.75rem;
    text-align: center;
    color: $base-color-white;
  }

  &__start {
    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__destination {
    justify-content: center;
    &--open {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__start-data {
    margin-right: auto;
  }

  &__change-over-overview {
    display: flex;
    justify-content: center;
    width: 100%;

    &--open {
      background-color: $base-color-blue;
    }
  }

  &__change-over-overview img {
    width: 25px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__legs-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &__arrow-toggle {
    margin-left: auto;
  }

  &__legs-button {
    display: flex;
    align-items: center;
    font-size: $base-font-size;
    height: 30px;
    min-width: 90px;
  }

  &__legs {
    width: 100%;
    background-color: $base-color-blue;
    padding-bottom: 20px;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }

  &__leg {
    background-color: $base-color-white;
    border-radius: 10px;
    color: $base-color-blue;
    padding: 10px;
  }

  &__change-over {
    width: 35px;
    margin-left: auto;
    margin-right: auto;
  }

  &__change-over img {
    width: 100%;
    height: 35px;
  }

  &__leg-card-button {
    margin-left: auto;
    margin-right: 0;
  }

  &__leg-card-comment-icon {
    margin-left: 5px;
  }
}

.journey-card + .journey-card {
  margin-top: 15px;
}
</style>