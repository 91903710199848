<template>
  <div class="container login-view">
    <div class="row">
      <div class="col-12">
        <div class="login-view__logo-wrapper">
          <h1>wimz</h1>
          <img class="login-view__logo" src="@/assets/logo.svg" alt="logo"/>
        </div>
        <p class="login-view__intro-text">
          Hallo und willkommen bei WIMZ! WIMZ ist eine Web-App für Reisende mit der Bahn, dem Bus und anderen ÖPNV Mitteln. 
          Es soll dafür gesorgt werden, dass man sich über Fahrten austauschen kann, damit man wirklich alle Informationen erhält, die man zur Planung einer Reise benötigt.
          <strong>Die App befindet sich aktuell noch in der Entwicklung und ist fehlerhaft. Es passieren noch ein paar Dinge die merkwürdig erscheinen - einiges, dass es noch zu tun gibt bis diese App getestet werden kann.</strong>
          Dennoch sind die Hauptfunktionen wie der Login, die Fahrtensuche oder Kommentare lesen und schreiben möglich. 
          Bei Fragen oder Anregungen könnt ihr mir gerne eine Mail an <a href="mailto:info@degemann.de">info@degemann.de</a> schicken. :)
          <strong>Leider wird Heroku am 28. November 2022 den <i>free plan</i> einstellen. <i>(Quelle:</i> <a href="https://blog.heroku.com/next-chapter" target="_blank">Heroku's Next Chapter</a><i>)</i></strong><br/>
          Voraussichtlich wird die Middleware von WIMZ dann zu einem anderen Anbieter umziehen. Ich entschuldige mich schon im Voraus, falls es zu dieser Zeit zu einer Downtime kommen sollte.
        </p>
      </div>
    </div>
    <form class="row login-view__form" @submit.prevent="handleSubmit">
      <div class="col-12 login-view__form-element">
        <basic-input 
          label="E-Mail/Nutzername"
          class="login-view__input"
          :value="email" 
          @change="email = $event.target.value"
          type="text" 
          name="username"
          :error="false"
        />
      </div>
      <div class="col-12 login-view__form-element">
        <basic-input 
          label="Passwort"
          class="login-view__input" 
          :value="password" 
          @change="password = $event.target.value"
          type="password" 
          name="password"
          :error="false"
        />
        <router-link class="login-view__password-link" to="forgot-password">Passwort vergessen?</router-link>
      </div>
      <div class="col-5 login-view__form-element">
        <button class="login-view__button login-view__button--secondary btn btn-secondary" @click.prevent="$router.push('sign-up')">Registrieren</button>
      </div>
      <div class="col-5 offset-2 login-view__form-element">
        <button class="login-view__button btn btn-primary">Login</button>
      </div>
      <div v-if="loading" class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </form>
  </div>
</template>

<script>
import { supabase } from "@/js/supabase/supabase.js";
import BasicInput from "../components/atoms/BasicInput.vue";

export default {
  components: { BasicInput },
  name: "LoginView",

  data() {
    return {
      email: "",
      password: "",
      loading: false,
      //error: "",
    };
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.loading = true;
      try {
        const { user, error } = await supabase.auth.signIn({
          email: this.email,
          password: this.password,
        });
        if (error) {
          this.error = error;
          if (error.status >= 400 && error.status < 500) {
            this.$store.commit("setNotification", {
              headline: "",
              text: "Diese Kombination ist leider nicht bekannt.",
              type: "error",
            });
          } else {
            this.$store.commit("setNotification", {
              headline: "",
              text: error.message,
              type: "error",
            });
          }
          this.loading = false;
          return;
        }
        if (user) {
          this.$router.push("search");
        } else {
          // this.error = "Unerwarteter Fehler ist aufgetreten";
        }
      } catch (e) {
        this.$store.commit("setNotification", {
          headline: "",
          text: e.message,
          type: "error",
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_vendors.scss";
.login-view {
  padding-top: $header-height + 50px;
  &__logo {
    width: 100px;
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $base-color-blue;
    width: 150px;
    height: 150px;
    border-radius: 30px;
    margin-right: auto;
    margin-left: auto;
    background-color: $base-color-medium-gray;
    box-shadow: 0px 0px 50px $base-color-medium-gray;
  }

  &__form,
  &__intro-text {
    margin-top: 80px;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
  }

  &__form {
    margin-bottom: 80px;
  }

  &__intro-text {
    max-width: 500px;
    color: $base-color-blue;
  }

  &__input,
  &__button {
    width: 100%;
  }

  &__button {
    &--secondary {
      background-color: transparent;
      border: none;
      color: $base-color-black;
    }
  }

  &__form-element + &__form-element {
    margin-top: 30px;
  }

  &__password-link {
    display: block;
    text-align: end;
  }
}
</style>

