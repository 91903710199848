<template>
  <div class="container logout-view">
    <div class="row">
      <div class="col-12">
        <div class="logout-view__logo-wrapper">
          <h1>wimz</h1>
          <img class="logout-view__logo" src="@/assets/logo.svg" alt="logo"/>
        </div>
        <div class="logout-view__message">
        <h2>Ausgeloggt</h2>
        <p>Weiterleitung zum Start...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from "@/js/supabase/supabase.js";
export default {
  name: "LogoutView",

  async mounted() {
    await supabase.auth.signOut();
    setTimeout(() => {
      this.$router.push("sign-in");
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_vendors.scss";
.logout-view {
  padding-top: $header-height + 50px;

  &__logo {
    width: 200px;
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $base-color-blue;
    width: 250px;
    height: 250px;
    border-radius: 30px;
    margin-right: auto;
    margin-left: auto;
    background-color: $base-color-medium-gray;
    box-shadow: 0px 0px 50px $base-color-medium-gray;
  }

  &__message {
    margin-top: 50px;
    text-align: center;
  }
}
</style>

